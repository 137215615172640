import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Images } from 'interfaces'

export const SiteDown: React.FC = (): JSX.Element => {
	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname + (window.location.search || ''),
			title: 'Site Down'
		})
	}, [])

	return (
		<div className="w-screen min-h-screen max-h-screen flex flex-col justify-start items-center overflow-hidden sm:bg-black-400 bg-neutral-500">
			<div className="w-full sm:w-[390px] h-[100vh]">
				<img
					src={Images?.siteDownMsg || ''}
					alt="Site Down Message"
					className="w-full h-full"
				/>
			</div>
		</div>
	)
}
