import React, { useLayoutEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
	// Explore,
	FAQ,
	History,
	// Home,
	PostStory,
	// Search,
	Profile,
	Register,
	Settings,
	Signin,
	// Splash,
	SubscribeConfirmation,
	AppleSubscribeConfirmation,
	// SubscriptionPricing,
	Terms,
	TrackingEvents,
	PrivacyPolicy,
	// LandingPage,
	SiteDown
	// BetNow
} from 'pages'
import { SubscriptionCancel } from 'pages/subscriptionCancel'
import { RequireSubscriber, RequireUser } from 'provider'
import { useStore } from 'stores'

export const RoutesElements = observer((): JSX.Element => {
	const location = useLocation()
	const { storyFilterFormStore } = useStore()
	const { setLeagueID, setSportID, clearStoryFilterForm } = storyFilterFormStore
	useLayoutEffect(() => {
		setLeagueID('')
		clearStoryFilterForm()
		setSportID('all')
	}, [location])

	return (
		<Routes>
			<Route
				path="/"
				element={<SiteDown />}
				errorElement={<div>Error! Something went wrong</div>}
			/>
			<Route path="/start" element={<SiteDown />} />
			<Route
				path="/home"
				element={
					// <RequireSubscriber>
					// 	<Home />
					// </RequireSubscriber>
					<SiteDown />
				}
			/>
			<Route
				path="/search"
				element={
					// <RequireSubscriber>
					// 	<Explore />
					// </RequireSubscriber>
					<SiteDown />
				}
			/>
			<Route
				path="/explore"
				element={
					// <RequireSubscriber>
					// 	<Explore />
					// </RequireSubscriber>
					<SiteDown />
				}
			/>
			<Route path="/market/:storyId" element={<PostStory />} />
			<Route path="/terms" element={<Terms />} />
			<Route path="/signin" element={<Signin />} />
			<Route path="/register" element={<Register />} />
			<Route
				path="/profile"
				element={
					<RequireUser>
						<Profile />
					</RequireUser>
				}
			/>
			<Route path="/faq" element={<FAQ />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route
				path="/settings"
				element={
					<RequireUser>
						<Settings />
					</RequireUser>
				}
			/>
			<Route
				path="/history"
				element={
					<RequireUser>
						<History />
					</RequireUser>
				}
			/>
			<Route
				path="/tracking-events"
				element={
					<RequireUser>
						<TrackingEvents />
					</RequireUser>
				}
			/>
			{/* <Route path="/subscribe" element={<SubscriptionPricing />} /> */}
			<Route path="/subscribe" element={<SiteDown />} />
			<Route
				path="/subscribe/confirmation/:session_id"
				element={
					<RequireUser>
						<SubscribeConfirmation />
					</RequireUser>
				}
			/>
			<Route
				path="/subscribe/apple/confirmation"
				element={
					<RequireUser>
						<AppleSubscribeConfirmation />
					</RequireUser>
				}
			/>
			<Route
				path="/subscription/cancel"
				element={
					<RequireSubscriber>
						<SubscriptionCancel />
					</RequireSubscriber>
				}
			/>
		</Routes>
	)
})
